import { mapGetters, mapMutations } from 'vuex';

// перше всеукраїнське бюро кредитних історій
const INSURANCE_PARTNER = 'pvbki';
// ідентифікатор перевірки по страховим базам
const DIAGNOSTIC_TYPE = 5;
// Ідентифікатор звіту страхових для прикладу
const EXAMPLE_REPORT_ID = 141780;

export default {
  i18n: require('./i18n').default,
  mixins: [
    require('../../../mixins/humanize'),
  ],
  name: 'InsuranceBases',
  components: {
    'vue-checking': require('../_CheckingVin/index.vue').default,
    'vue-withoutVin': require('../_withoutVinStep/index.vue').default,
  },
  props: ['advertisementId'],
  data() {
    return {
      orderState: 'ordering',
      logDataDefault: {
        action_block: 5,
        block_type: 5,
      },
    };
  },
  computed: {
    ...mapGetters({
      advertisements: 'graphql/advertisements',
      vinChecksPrices: 'others/autotest/autotestVinCheckPrices',
    }),
    advertisement() {
      return this.advertisements(this.advertisementId);
    },
    price() {
      return (
        this.vinChecksPrices
          && this.vinChecksPrices[INSURANCE_PARTNER]?.price
      ) || 0;
    },
    priceHumanize() {
      return this.humanizeNumber(this.price);
    },
    originPrice() {
      return (
          this.vinChecksPrices
          && this.vinChecksPrices[INSURANCE_PARTNER]?.originPrice
      ) || 0;
    },
    originPriceHumanize() {
      console.log('this.originPrice', this.originPrice)
      return this.humanizeNumber(this.originPrice);
    },
    percentDiscount() {
      return this.vinChecksPrices && this.vinChecksPrices[INSURANCE_PARTNER]?.percentDiscount;
    },
    hasSomeDiscount() {
      return this.price < this.originPrice;
    },
    profit() {
      return Math.round(this.vinChecksPrices?.[INSURANCE_PARTNER]?.profit) || 0;
    },
    diagnosticType() {
      return (
        this.vinChecksPrices
          && this.vinChecksPrices[INSURANCE_PARTNER]?.type
      ) || DIAGNOSTIC_TYPE;
    },
    order() {
      return {
        name: `${this.$t('перевірку')} ${this.$t('по страховим базам')}`,
        advertisementId: this.advertisementId,
        profit: this.profit,
        payload: {
          price: this.price,
          diagnostic_type: this.diagnosticType,
          type: 'vin',
        },
      };
    },
    exampleReportLink() {
      return `/uk/insurance-check/${EXAMPLE_REPORT_ID}`;
    },
  },
  methods: {
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    viewExample() {
      this.autotestLoggerData({ ...this.logDataDefault, click_action: 1 });
    },
    buyCheck() {
      this.orderState = 'checking';
      this.autotestLoggerData({
        ...this.logDataDefault,
        click_action: 2,
        request_type: 2,
        payment_summ: this.price,
        initial_payment: this.profit,
      });
    },
  },
  mounted() {
    this.autotestLoggerDefaultData({
      autotest_insurance_verifications: 1,
      autotest_price_insurance_verifications: this.price || 0,
    });
  },
};
