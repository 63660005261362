export default {
	messages: {
		uk: {
			Отримайте: 'Отримайте',
			вигоди: 'вигоди',
			'на перевірки, для вас та ваших покупців': 'на перевірки, для вас та ваших покупців',
			'Вам доступна': 'Вам доступна',
			'на всі типи перевірок': 'на всі типи перевірок',
			вигода: 'вигода',
			'Тільки для': 'Тільки для',
			Детальніше: 'Детальніше',
			'Продавець потурбувався про вас': 'Продавець потурбувався про вас',
			'на будь-яку перевірку': 'на будь-яку перевірку',
		},
		ru: {
			Отримайте: 'Получите',
			вигоди: 'выгоды',
			'на перевірки, для вас та ваших покупців': 'на проверки для вас и ваших покупателей',
			'Вам доступна': 'Вам доступна',
			'на всі типи перевірок': 'на все типы проверок',
			вигода: 'выгода',
			'Тільки для': 'Только для',
			Детальніше: 'Подробнее',
			'Продавець потурбувався про вас': 'Продавец позаботился о вас',
			'на будь-яку перевірку': 'на любую проверку',
		},
	}
};
