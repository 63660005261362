import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'CebiaReport',
  props: ['cebiaReportLink'],
  components: {
    'vue-checking': require('../_CheckingVin/index.vue').default,
    'vue-withoutVin': require('../_withoutVinStep/index.vue').default,
  },
  data() {
    return {
      dataChecks: {
        list: [
          'Справжній пробіг',
          'Перевірка на пошкодження',
          'Фото з попередніх продажів',
          'Справжній рік та країна виготовлення',
          'Перевірка на крадіжку',
          'Перевірка роботи в таксі',
          'Детальна комплектація авто',
        ],
        price: 629,
      },
    };
  },
  methods: {
    ...mapMutations({
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    orderInspection() {
      this.autotestLoggerData({
        ...this.logDataDefault,
        action_block: 9,
        click_action: 2,
        block_type: 9,
        info_block: 1,
        balance_personal: 9,
      });
    },
  },
  i18n: require('./i18n').default,
};
