export default {
  messages: {
    uk: {
      'Перевірка історії авто за кордоном': 'Перевірка історії авто за кордоном',
      'Перевірте історію авто за кордоном з Cebia': 'Перевірте історію авто за кордоном з Cebia',
      'Компанія “Cebia” спеціалізується на даних, зафіксованих за VIN-кодом авто: пробіги, продажі на онлайн-платформах, сервісне обслуговування тощо': 'Компанія “Cebia” спеціалізується на даних, зафіксованих за VIN-кодом авто: пробіги, продажі на онлайн-платформах, сервісне обслуговування тощо',
      'Справжній пробіг': 'Справжній пробіг',
      'Перевірка на пошкодження': 'Перевірка на пошкодження',
      'Фото з попередніх продажів': 'Фото з попередніх продажів',
      'Справжній рік та країна виготовлення': 'Справжній рік та країна виготовлення',
      'Перевірка на крадіжку': 'Перевірка на крадіжку',
      'Перевірка роботи в таксі': 'Перевірка роботи в таксі',
      'Детальна комплектація авто': 'Детальна комплектація авто',
      'Ціна': 'Ціна',
      'Замовити перевірку': 'Замовити перевірку',
    },
    ru: {
      'Перевірка історії авто за кордоном': 'Проверка истории авто за границей',
      'Перевірте історію авто за кордоном з Cebia': 'Проверьте историю авто за границей с Cebia',
      'Компанія “Cebia” спеціалізується на даних, зафіксованих за VIN-кодом авто: пробіги, продажі на онлайн-платформах, сервісне обслуговування тощо': 'Компания Cebia специализируется на данных, зафиксированных по VIN-коду авто: пробеги, продажи на онлайн-платформах, сервисное обслуживание и т.д.',
      'Справжній пробіг': 'Настоящий пробег',
      'Перевірка на пошкодження': 'Проверка повреждения',
      'Фото з попередніх продажів': 'Фото с предварительных продаж',
      'Справжній рік та країна виготовлення': 'Настоящий год и страна изготовления',
      'Перевірка на крадіжку': 'Проверка на воровство',
      'Перевірка роботи в таксі': 'Проверка работы в такси',
      'Детальна комплектація авто': 'Подробная комплектация авто',
      'Ціна': 'Цена',
      'Замовити перевірку': 'Заказать проверку',
    },
  }
};
