import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  props: ['advertisementId'],
  data() {
    return {
      discount: '-10%',
      serviceName: 'sellerPage',
      isMounted: false,
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessFeaturesData: 'proAccount/fetchBusinessFeaturesData',
      fetchBusinessFeaturesDataOnServer: 'proAccount/fetchBusinessFeaturesDataOnServer',
    }),
    ...mapMutations({
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    getDiscount() {
      this.autotestLoggerData({
        ...this.logDataDefault,
        action_block: 15,
        click_action: 1,
        block_type: 1,
        current_url: window.location.pathname,
      });
    },
    proBusinessFeaturesData(data){
      return data?.[this.serviceName] || null
    },
  },
  computed: {
    ...mapGetters({
      getProBusinessFeaturesData: 'proAccount/getProBusinessFeaturesData',
      getProBusinessFeaturesDataById: 'proAccount/getProBusinessFeaturesDataById',
      advertisements: 'graphql/advertisements',
    }),

    isActiveProSeller(){
      return this.proBusinessFeaturesData(this.getProBusinessFeaturesData)?.active;
    },
    isDeactivatedProSeller(){
      return this.proBusinessFeaturesData(this.getProBusinessFeaturesData)?.deactivated;
    },
    isSellerPro(){
      return Boolean(this.isActiveProSeller || this.isDeactivatedProSeller);
    },

    ownerIsActiveProSeller(){
      return this.proBusinessFeaturesData(this.getProBusinessFeaturesDataById)?.active;
    },
    ownerId() {
      return this.advertisements(this.advertisementId)?.owner?.id || null;
    },
    isShow(){
      return this.isMounted && (this.isSellerPro || this.ownerIsActiveProSeller)
    }
  },
  async serverPrefetch() {
    await this.fetchBusinessFeaturesDataOnServer({name: this.serviceName, userId: this.ownerId});
  },
  async mounted() {
    await this.fetchBusinessFeaturesData({name: this.serviceName});
    this.isMounted = true;
  },
  i18n: require('./i18n').default,
};
