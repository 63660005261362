import { mapGetters, mapMutations } from 'vuex';
import store from '../../../../mixins/store';
import constants from '../constants';
import {
  autotestGetSTOVerificationsPriceForBrandYear,
  getStationsInCityRegion,
  cheapestSTOServices,
  getCities,
} from '../../../../store/queries';

import {
  TECHNICAL_CHECKS_MY,
  TECHNICAL_CHECKS_ALL,
  COMPUTER_DIAGNOSTICS_ID,
  ELECTRIC_FUEL_ID,
} from '../../../../../constants/autotest';

function computeParamsForGetCities(advertisement, langId) {
  const {
    category: { id: categoryId } = {},
    brand: { id: brandId } = {},
    model: { id: modelId } = {},
    body: { id: bodyId } = {},
    fuel: { id: fuelId } = {},
    year,
    location: {
      state: { id: stateId } = {},
      city: { id: cityId } = {},
    } = {},
  } = advertisement || {};

  return ({
    regionId: stateId,
    cityId,
    categoryId,
    subcategoryId: bodyId,
    brandId,
    modelId,
    year,
    langId,
    fuelId,
  });
}

function computeParamsForGetCitySTO(advertisement, cityId, langId) {
  const {
    location: {
      city: { id: regionCityId } = {},
    } = {},
    brand: { id: brandId } = {},

    body: { id: subcategoryId } = {},
    fuel: { id: fuelId } = {},
  } = advertisement || {};
  return ({
    regionCityId, cityId, brandId, subcategoryId, fuelId, langId,
  });
}

function getValueOfFirst(items = []) {
  const [{ value = 0 } = {}] = (Array.isArray(items) ? items : []);
  return value;
}

export default {
  i18n: require('./i18n').default,
  mixins: [
    store(['cities', 'cityId', 'stos', 'stoId', 'services'], function () {
      this.$nextTick(() => (this.restored = true));
    }),
    require('../../../../mixins/humanize'),
  ],
  props: ['advertisementId', 'logDataDefault', 'techCheck3500'],
  data() {
    return ({
      exampleLink: constants.example,
      electricExampleLink: constants.electricExample,
      cities: [],
      cityId: 0,
      stos: [],
      stoId: 0,
      services: [],
      servicesChecked: {},
      servicesMap: {},
      TECHNICAL_CHECKS_MY,
      TECHNICAL_CHECKS_ALL,
      restored: false,
      nearestStations: false,
      logActionClick: 0,
    });
  },
  computed: {
    ...mapGetters({
      advertisements: 'graphql/advertisements',
    }),
    advertisement() {
      return this.advertisements(this.advertisementId);
    },
    advertisementBrandId() {
      const { brand: { id: brandId } = {} } = this.advertisement || {};
      return brandId;
    },
    advertisementYear() {
      const { year } = this.advertisement || {};
      return year;
    },
    priceCurrent() {
      const totalPrice = this.services
        .filter(({ package_id } = {}) => this.servicesChecked[package_id])
        .reduce((a, { price } = {}) => a + price, 0);
      return totalPrice;
    },
    priceCurrentHumanize() {
      return this.humanizeNumber(this.priceCurrent);
    },
    originPrice() {
      const totalOriginPrice = this.services
        .filter(({ package_id } = {}) => this.servicesChecked[package_id])
        .reduce((a, { originPrice } = {}) => a + originPrice, 0);
      return totalOriginPrice;
    },
    originPriceHumanize() {
      return this.humanizeNumber(this.originPrice);
    },
    priceAll() {
      return this.services
        .reduce((a, { price } = {}) => a + price, 0);
    },
    hasSomeDiscount() {
      return this.priceCurrent < this.originPrice;
    },
    isElectric() {
      return Number(this.advertisement?.fuel?.id) === ELECTRIC_FUEL_ID;
    },
    exampleReportLink() {
      return !this.isElectric ? this.exampleLink : this.electricExampleLink;
    },
    percentDiscount() {
      return this.services.length && this.services[0]?.percentDiscount;
    },
  },
  methods: {
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    servicesEdition(fetchedServices) {
      this.services.splice(0, this.services.length, ...fetchedServices);

      const compDiagnIndex = this.services.findIndex(({ package_id } = {}) => package_id === COMPUTER_DIAGNOSTICS_ID);

      if (!this.techCheck3500) return this.services;

      // переміщаємо пункт 'Комп'ютерна діагностика' на 4 місце
      this.services.splice(4, 0, this.services[compDiagnIndex]);
      // забираємо його з першого
      this.services.splice(0, 1);
      return this.services;
    },
    fetchCitiesList() {
      const citiesArgs = computeParamsForGetCities(this.advertisement, this.langId);
      return getCities(citiesArgs)
        .then((cities = []) => {
          const advCityIndex = cities.findIndex((city) => city.value === Number(citiesArgs?.cityId));
          // Якщо серед міст з СТО нема міста з оголошення, то показуєм плашку 'Найближчі СТО'
          if (advCityIndex === -1) this.nearestStations = true;
          this.cities = cities;
          return cities;
        });
    },
    fetchSTOList(cityId, setSTO = true) {
      return getStationsInCityRegion(
        computeParamsForGetCitySTO(this.advertisement, cityId, this.langId),
      ).then((stations) => {
        if (stations) {
          this.stos = stations;
          const stoId = getValueOfFirst(this.stos);
          if (setSTO && this.stoId !== stoId) this.stoId = stoId;

          return stoId;
        }
      });
    },
    async fetchCheapestSTOServices() {
      try {
        const cheapest = await cheapestSTOServices(this.advertisementId, this.langId);
        this.cityId = cheapest.stationCityId;
        await this.fetchSTOList(cheapest.stationCityId, false);
        this.stoId = cheapest.stationId;
        this.services = cheapest.services;
        this.servicesEdition(cheapest.services);
        return cheapest;
      } catch (err) {
        console.error(`${__filename}/fetchCheapestSTOServices(${this.advertisementId}) error: `, err?.message);
      }
    },
    async fetchSTOServicesList(args) {
      try {
        const fetchedServices = await autotestGetSTOVerificationsPriceForBrandYear(args);
        this.servicesEdition(fetchedServices);
        return fetchedServices;
      } catch (err) {
        console.error(`${__filename}/fetchSTOServicesList(${this.advertisementId}) error: `, err?.message);
      }
    },
    onViewExample() {
      this.sendLogs({ click_action: 1 });
      this.gaEvent('Сheck_Auto', 'Click_on_link_sample_report_technical_new', 'check_selection');
    },
    onCheckPackageId(id) {
      this.sendLogs({
        click_action: 5,
        autotest_technical_verifications_services_id: id,
        autotest_technical_verifications_services_id_status: !this.servicesChecked[id] ? 1 : 2,
        autotest_technical_verifications_services_id_price: this.servicesMap[id],
      });
    },
    onChoose(type) {
      const { cityId, stoId } = this;

      const verifications = type === TECHNICAL_CHECKS_ALL
        ? []
        : Object.entries(this.servicesChecked).filter(([, value]) => value).map(([key]) => key);

      const price = type === TECHNICAL_CHECKS_ALL ? this.priceAll : this.priceCurrent;

      this.$emit('choosed', {
        nameDative: this.$t('техническую проверку'),
        advertisementId: this.advertisementId,
        payload: {
          cityId,
          packageId: type,
          stoId,
          sto: { stoId },
          price,
          type: 'technical',
          verifications,
        },
      });

      this.sendLogs({
        click_action: 2,
        request_type: 2,
        payment_summ: this.priceCurrent || 0,
      });
    },
    formingTechLogData() {
      const checked = [];
      Object.keys(this.servicesChecked).forEach((id) => {
        if (this.servicesChecked[id]) checked.push(id);
      });

      return {
        autorazborka_id: Object.keys(this.servicesMap),
        balance_promotional: Object.values(this.servicesMap),
        autosalon_id: checked,
        balance_publications: checked.map((id) => (this.servicesMap[id] || 0)),
        autotest_price_technical_verifications: this.priceCurrent || 0,
        autotest_technical_verifications_city_id: this.cityId || 0,
        autotest_technical_verifications_sto_id: this.stoId || 0,
      };
    },
    createServicesMap() {
      try {
        this.servicesMap = {};
        if (Array.isArray(this.services)) {
          this.services.forEach(({ package_id: id = 0, price }) => {
            this.servicesMap[id] = price;
          });
        }
      } catch (e) {
        console.error('===ERROR in createMapServices===', e.toString());
      }
    },
    sendLogs(logData = {}) {
      this.autotestLoggerData({
        ...(this.logDataDefault || {}),
        ...this.formingTechLogData(),
        ...logData,
      });
    },
  },
  watch: {
    services(newValue) {
      const isSegmentChecked = newValue.some(({ active }) => active);
      newValue.map((item) => {
        const pckgId = item.package_id;
        if (isSegmentChecked) {
          if (item.active) this.$set(this.servicesChecked, pckgId, true);
        } else if (!this.techCheck3500) {
          if (pckgId !== 13) this.$set(this.servicesChecked, pckgId, true);
        } else {
          const abTestPckgsIds = [5, 7, 8];
          abTestPckgsIds.forEach((id) => this.$set(this.servicesChecked, id, true));
        }
      });
      this.createServicesMap();
      if (newValue.length) {
        this.autotestLoggerDefaultData({ ...this.formingTechLogData() });
        if (this.logActionClick) {
          this.sendLogs({ click_action: this.logActionClick });
          this.logActionClick = 0;
        }
      }
    },
    restored: {
      handler() {
        this.$watch('cityId', function (newCityId, oldCityId) {
          // Do nothing on very first city change
          if (oldCityId === 0) return;

          this.stoId = 0;
          if (newCityId) return this.fetchSTOList(newCityId);
        });

        this.$watch('stoId', function (stoId) {
          this.services.splice(0, this.services.length);

          if (stoId) {
            this.fetchSTOServicesList({
              stoId,
              brandId: this.advertisementBrandId,
              year: this.advertisementYear,
              langId: this.langId,
              autoId: this.advertisementId,
            });
          }
        });
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.cities.length) {
      // First we fetch cities
      await this.fetchCitiesList();
    }
    // Next fetch the chipest sto in the cities
    this.fetchCheapestSTOServices();
    this.autotestLoggerDefaultData({ autotest_technical_verifications: 1 });
  },
};
